import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Email } from '@mui/icons-material';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  styled,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/User';
import '../theme/popup.css';
import { applySortFilter, getComparator } from '../utils/Utilities';
import { baseUrl, defaultToastProps, userSessionData } from '../utils/constants';
import DeleteDailog from './Projects/deleteDialog';
import {plan} from '../utils/plans';

const CustomAutoComplete = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d9dd',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
}));

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'project', label: 'Projects', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_ADMIN = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false }, // Add this line for email column
  { id: 'project', label: 'Projects', alignRight: false },
  { id: 'organization', label: 'Organization', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function ProjectPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isBlurActive, setIsBlurActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');

  const [project, setProject] = useState([]);
  const [projects, setProjects] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const user = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

  const [emailError, setEmailError] = useState('');
  const validateEmail = (value) => {
    // Use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  // const userDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

  const handleOpenPopover = (rowData) => {
    if (rowData) {
      setFirstName(rowData.firstname);
      setLastName(rowData.lastname);
      setEmail(rowData.email);
      setUserId(rowData?.id);
      setProject([]);
    }

    setIsPopoverOpen(true);
    setIsBlurActive(true); // Activate blur when the popover opens
  };

  const handleUserSubmit = () => {
    if(plan[user.plan].user<=totalCount){
      toast.error(`You have ${plan[user.plan].user} users as per you plan. Limit Exeeded!`);
      return;
    }
    if (!firstName) {
      toast.error('Please fill in required fields (First Name)');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address');
      return;
    }

    if (!lastName) {
      toast.error('Please fill in required fields (Last Name)');
      return;
    }

    if (!email) {
      toast.error('Please fill in required fields (Email)');
      return;
    }

    if (project.length === 0) {
      toast.error('Please select at least one project');
      return;
    }

    if (!password) {
      toast.error('Please enter a password');
      return;
    }

    if (password.length < 8) {
      toast.error('Password must be at least 8 characters long');
      return;
    }

    if (!confirmPassword) {
      toast.error('Please confirm your password');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    const SelectedProj = project?.map((proj) => proj.id);

    if (selectedRow) {
      axios
        .put(`${baseUrl}/users/edit`, {
          firstname: firstName,
          lastname: lastName,
          email,
          password,
          role: 'user',
          project: SelectedProj,
          id: userId,
          // primary_id: userDetails?.id,
        })
        .then((response) => {
          handleClosePopover();
          toast.success('Project updated successfully', defaultToastProps);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000); // Delay the reload by 1 second (you can adjust the delay as needed)
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`${baseUrl}/users/create`, {
          firstname: firstName,
          lastname: lastName,
          email,
          password,
          role: 'user',
          project: SelectedProj,
          primary_id: user?.id,
          organization: user.organization,
          plan: user.organization,
          valid_from: user.valid_from,
          valid_till: user.valid_till,

        })
        .then((response) => {
          handleClosePopover();
          console.log('User created successfully');
          toast.success('User created successfully', defaultToastProps);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000); // D
        })
        .catch((err) => console.log(err));
    }

    setEmailError('');
  };

  // If all validations pass, you can proceed with the user creation
  //   const SelectedProj = project?.map((proj) => proj.id);
  //   axios
  //     .post(`${baseUrl}/users/create`, {
  //       firstname: firstName,
  //       lastname: lastName,
  //       email,
  //       password,
  //       role: 'user',
  //       project: SelectedProj,
  //       primary_id: userDetails?.id,
  //     })
  //     .then((response) => {
  //       handleClosePopover();
  //       console.log('User created successfully');
  //       toast.success('User created successfully', defaultToastProps);
  //       setTimeout(() => {
  //         window.location.reload(false);
  //       }, 1000); // D
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error('Failed to create user', defaultToastProps);
  //     });
  // };

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setIsBlurActive(false); // Deactivate blur when the popover closes
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const fetchProjects = async () => {
    axios
      .get(`${baseUrl}/projects/my-project`)
      .then((res) => {
        if (res && res.data.status === 200) {
          setProjects(res.data.data.list);
          // setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('error');
        // setShowLoader(false);
      });
  };

  const fetchData = async (offset, limit) => {
    const endpoints = user?.role === 'admin' ? 'all' : 'my-members';
    axios
      .get(`${baseUrl}/users/${endpoints}?limt=${limit}&offset=${offset}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          setRows(res.data.data.list);
          setTotalCount(Number(res.data.data?.list?.length));
          // setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('error');
        // setShowLoader(false);
      });
  };

  const handleDeleteApiCall = async (id) => {
    setOpenDeleteModal(false);
    setSelectedRow(null);
    axios
      .delete(`${baseUrl}/users/remove/${id}`)
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        console.log('error');
      });
  };

  function getUserDetails(id) {
    axios
      .get(`${baseUrl}/users/get/${id}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          const userData = res.data?.data;
          setFirstName(userData?.firstname);
          setLastName(userData?.lastname);
          setEmail(userData?.email);
          setUserId(userData?.id);
          const proj = projects.filter((item) => userData?.project.includes(item?.id));
          setProject(proj);
        }
      })
      .catch((err) => {
        console.log('error');
      });
  }

  useEffect(() => {
    fetchData(offset, limit);
    fetchProjects();
  }, [limit, offset]);

  useEffect(() => {
    // get
  }, []);

  return (
    <>
      <div className={`container ${isBlurActive ? 'blur' : ''}`}>
        <div className={`background-blur ${isBlurActive ? 'active' : ''}`} />
        <Helmet>
          <title> Users</title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>
            {userSessionData()?.role !== 'admin' && (
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenPopover}>
                New Users
              </Button>
            )}
          </Stack>

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={userSessionData()?.role === 'admin' ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                    rowCount={rows.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    hideCheckBox
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, firstname, lastname, email, avatarUrl, project, organization, role } = row;
                      const selectedUser = selected.indexOf(firstname) !== -1;
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={firstname} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {`${firstname} ${lastname}`}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell> {/* Add this line for email column */}
                          <TableCell align="left">
                          <Button
                            variant="outlined"
                            style={{borderColor:"white"}}
                            onClick={() =>
                              navigate(`/projects/${row?.id}`, { replace: false, state: { row } })
                            }
                            disabled={project<1}

                          >
                            {project}
                          </Button>
                        </TableCell>
                          {userSessionData()?.role === 'admin' && (
                            <>
                              <TableCell align="left">{organization}</TableCell>
                              <TableCell align="left">{role}</TableCell>
                            </>
                          )}
                          <TableCell align="right" width={'20%'}>
                            {user?.role !== 'admin' && (
                              <>
                                <IconButton
                                  size="large"
                                  onClick={() => {
                                    getUserDetails(row?.id);
                                    setSelectedRow(row);
                                    handleOpenPopover(row);
                                  }}
                                >
                                  <Iconify icon={'eva:edit-fill'} />
                                </IconButton>

                                <IconButton
                                  size="large"
                                  sx={{ color: 'error.main' }}
                                  onClick={() => {
                                    setSelectedRow(row);
                                    setOpenDeleteModal(true);
                                  }}
                                >
                                  <Iconify icon={'eva:trash-2-outline'} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        {openDeleteModal && (
          <DeleteDailog
            data={selectedRow}
            handleClose={() => setOpenDeleteModal(false)}
            handleDeleteClicked={(data) => handleDeleteApiCall(data?.id)}
            visible={openDeleteModal}
          />
        )}

        <Popover
          open={isPopoverOpen}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'center', // Center vertically
            horizontal: 'center', // Center horizontally
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          classes={{ paper: 'centered-popover' }} // Add a custom CSS class
        >
          <Paper sx={{ padding: 4, width: 600 }}>
            <div className="popover-header">
              <IconButton
                size="small"
                onClick={handleClosePopover}
                style={{ position: 'absolute', top: '0px', right: '0px' }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: '60px', flex: 1, width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{ width: '48%' }}
                />
                <TextField
                  name="lastName"
                  label="Last Name"
                  style={{ width: '48%' }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
                <TextField
                  name="email"
                  label="Email address"
                  value={email}
                  style={{ width: '48%' }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(''); // Reset email error when typing
                  }}
                  error={!!emailError}
                  helperText={emailError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{emailError && <Email color="error" />}</InputAdornment>
                    ),
                  }}
                />
                <CustomAutoComplete
                  disablePortal
                  multiple
                  id="combo-box-demo"
                  size="medium"
                  style={{ width: '48%' }}
                  options={projects}
                  getOptionLabel={(option) => option.name}
                  value={project}
                  onChange={(_, newValue) => {
                    setProject(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Project" />}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: '48%' }}
                />
                <TextField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ width: '48%' }}
                />
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUserSubmit}
              style={{ width: '40%', marginTop: 32, height: 50 }}
            >
              Save
            </Button>
          </Paper>
        </Popover>
      </div>
    </>
  );
}
