export const plan={ 
    Free : {
    cpu: 10,
    region: 1,
    tests: 1,
    user:1
  },
  Basic : {
    cpu: 50,
    region: 10,
    tests: 3,
    user:5
  },
  Premium : {
    cpu: 100,
    region: 50,
    tests: 5,
    user:15
  },
  Enterprise : {
    cpu: 0,
    region: 0,
    tests: 10,
    user:30
  }
}