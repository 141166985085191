import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Nav from '../layouts/dashboard/nav';
import Header from '../layouts/dashboard/header';

export default function HTMLReportPage() {
  const locationState = useLocation();
  const testData = locationState?.state?.row;
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate('/tests', { replace: false, state: { data: testData } });
  };

  useEffect(() => {
    // Fetch or load the HTML content from the provided URL
    // This can be done using iframe or any other method depending on your requirements
  }, []);

  return (
    <>
      <Helmet>
        <title>HTML Report</title>
      </Helmet>
      <Nav />
      <Header />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ArrowBackIcon onClick={handleBackButtonClick} style={{ cursor: 'pointer',  marginBottom:'10px', color:'white', backgroundColor:'black', padding:'3px' }} />
       
      </div>

      {/* Use an iframe to display the HTML content */}
      <iframe
        src={testData?.report_html}
        width="100%"
        height="1000"
        title="HTML Report"
      />
    </>
  );
}
