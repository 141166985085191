import React from 'react';
import { Button, Dialog, IconButton, Paper, Popover, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function DeleteDailog({
  data,
  visible = true,
  handleClose = () => {},
  dialogTitle = () => {},
  handleDeleteClicked = () => {},
}) {
  return (
    <Popover
      open={visible}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center', // Center vertically
        horizontal: 'center', // Center horizontally
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      classes={{ paper: 'centered-popover' }} // Add a custom CSS class
    >
      <Paper sx={{ padding: 3, width: 400 }}>
        <div className="popover-header">
          <IconButton size="small" onClick={handleClose} style={{ position: 'absolute', top: '8px', right: '8px' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography
          variant="subtitle1"
          noWrap
          style={{ marginTop: 40, alignSelf: 'center', alignItems: 'center', textAlign: 'center' }}
        >
          Are you sure ?
        </Typography>
        <div
          style={{
            padding: '10px 20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            style={{ marginTop: 20, flex: 1, marginRight: 10 }}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleDeleteClicked(data)}
            style={{ marginTop: 20, flex: 1, marginLeft: 10 }}
          >
            Yes
          </Button>
        </div>
      </Paper>
    </Popover>
  );
}
