
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from "styled-components";

import {
    Button
  } from '@mui/material';
import { baseUrl } from '../utils/constants';


const CheckoutSuccess = () => {
    const navigate = useNavigate();  
    useEffect(() => {
      const userDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

    axios
      .get(`${baseUrl}/users/get/${userDetails.id}`)
      .then((response) => {
        console.log("response---",response);
        const data=response?.data?.data
        data.token=userDetails.token
        sessionStorage.setItem('authUser', JSON.stringify(data));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
})
   
    return (
        <Container>
            <h2>Checkout Successful</h2>
            <p>Your order might take some time to process.</p>
            <Button
                variant="outlined"
                style={{backgroundColor:'#029e02',color:"white"}}
                onClick={() => navigate(`/dashboard`, { replace: false})}
            >
                Go to Home
            </Button>
        </Container>
    );
};

export default CheckoutSuccess;

const Container = styled.div`
  min-height: 80vh;
  max-width: 800px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 0.5rem;
    color: #029e02;
  }
`;