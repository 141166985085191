import { Container, Typography, Grid, Stack, Button, Paper, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import Nav from '../layouts/dashboard/nav';
import Header from '../layouts/dashboard/header';
import { baseUrl } from '../utils/constants';

export default function TestDetails() {
  const userDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

  const locationState = useLocation();
  const testData = locationState?.state?.row;

  const [open, setOpen] = useState(false);
  const [testDetails, setTestDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate('/tests', { replace: false, state: { data: testData } });
  };


  useEffect(() => {
    const fetchProjects = async () => {
      axios
        .get(`${baseUrl}/projects/my-project`)
        .then((res) => {
          if (res && res.data.status === 200) {
            setProjects(res.data.data.list);
          }
        })
        .catch((err) => {
          console.log('error');
        });
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    const getTestDetails = () => {
      axios
        .get(`${baseUrl}/test/get/${testData?.id}`)
        .then((res) => {
          if (res && res.data.status === 200) {
            const test = res.data.data;
            const filtered = projects.find((proj) => proj.id === test.project_id);
            test.projects = filtered;
            console.log('====================================');
            console.log(test);
            test.jmx_name = test.jmx?.substring(test.jmx?.lastIndexOf('/') + 1, test.jmx?.length)
            if(test.extra_file){
              // const extraFile=test.extra_file.substring(1,test.extra_file.length-1)
              // test.extra_file=extraFile.split(',')
              // console.log("test.extra_file  ",test.extra_file);

            }
            console.log('====================================');
            setTestDetails(test);
          }
        })
        .catch(() => {
          console.log('error');
          // setShowLoader(false);
        });
    };
    if (projects?.length > 0) {
      getTestDetails();
    }
  }, [projects]);

  return (
    <>
      <Helmet>
        <title>Test-Form</title>
      </Helmet>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIcon
          onClick={handleBackButtonClick}
          style={{
            cursor: "pointer",
            marginLeft: 0,
            marginBottom: "10px",
            color: "white",
            backgroundColor: "black",
            padding: "3px",
          }}
        />
      </div>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Container style={{}}>
        <Grid
          xs={12}
          direction={"row"}
          container
          mb={3}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h4" style={{ color: "black" }}>
            Test Details
          </Typography>
          {userDetails.plan !== "Free" && (
            <Grid
              container
              xs={12}
              md={9}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Grid xs={12} md={3} marginBottom={2} marginTop={2}>
                <a
                  href={testDetails?.report_jtl}
                  download={`${testDetails?.name} - Report`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="contained" onClick={() => {}}>
                    {"Download JTL File"}
                  </Button>
                </a>
              </Grid>
              <Grid xs={12} md={4} marginBottom={2}>
                <a
                  href={testDetails?.report_output}
                  download={`${testDetails?.name} - Report Output`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="contained" onClick={() => {}}>
                    {"Download Report Folder"}
                  </Button>
                </a>
              </Grid>
            </Grid>
          )}
        </Grid>
        <p style={{ color: "red" }}>{testDetails?.comment}</p>
        <Stack spacing={3}>
          <Paper
            elevation={3}
            style={{
              padding: "20px",
              marginTop: "20px",
              borderRadius: "8px",
              backgroundColor: "#2065d10f",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <div style={{ marginLeft: 30, marginTop: 30 }}>
              <Grid container xs={12} spacing={2}>
                <Grid xs={12} md={3} marginBottom={2}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Name
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #B8B8B8	",
                      padding: "8px",
                      borderRadius: "4px",
                      width: "80%",
                    }}
                  >
                    <Typography>{testDetails?.name}</Typography>
                  </div>
                </Grid>
                <Grid xs={12} md={3} marginBottom={2}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Projects
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #B8B8B8	",
                      padding: "8px",
                      borderRadius: "4px",
                      width: "80%",
                    }}
                  >
                    <Typography>
                      {testDetails?.projects?.name || "NA"}
                    </Typography>
                  </div>
                </Grid>
                <Grid xs={12} md={6} marginBottom={2}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    JMX File
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #B8B8B8	",
                      padding: "8px",
                      borderRadius: "4px",
                      width: "95%",
                    }}
                  >
                    {/* test.jmx?.substring(test.jmx?.lastIndexOf('/')+1,test.jmx?.length) */}
                    {/* <Typography>{testDetails?.jmx || 'NA'}</Typography> */}
                    <a
                      href={testDetails?.jmx}
                      download={`${testDetails?.jmx} - Report`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button variant="contained" onClick={() => {}}>
                        {testDetails?.jmx_name.slice(
                          testDetails?.jmx_name.indexOf("_") + 1
                        )}
                      </Button>
                    </a>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                xs={12}
                style={{ marginTop: 0 }}
              >
                <Grid xs={12} md={6} marginBottom={2}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Extra Files
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #B8B8B8	",
                      overflow: "scroll",
                      padding: "8px",
                      borderRadius: "4px",
                      width: "90%",
                    }}
                  >
                    {testDetails?.extra_file?.map(({ file, split }, i) => (
                      <div>
                        <a
                          href={file}
                          download={`${testDetails?.jmx} - Report`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button variant="contained" onClick={() => {}}>
                            {file.slice(file.indexOf("_") + 1)}
                          </Button>
                        </a>
                        <FormControlLabel
                          control={<Checkbox checked={split}  />}
                          label="Split"
                          style={{ color: "black" }}
                          labelPlacement="start"
                        />
                      </div>
                      // <Typography>{data || 'NA'}</Typography>
                    ))}
                  </div>
                </Grid>
                <Grid xs={12} md={6} marginBottom={2}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    JMX Version
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #B8B8B8	",
                      padding: "8px",
                      borderRadius: "4px",
                      width: "95%",
                    }}
                  >
                    <Typography>{testDetails?.jm_version || "NA"}</Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                xs={12}
                style={{ marginTop: 0 }}
              >
                <Grid xs={12} marginBottom={2}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Optional Description
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #B8B8B8	",
                      padding: "8px",
                      borderRadius: "4px",
                      width: "97.5%",
                    }}
                  >
                    <Typography>{testDetails?.description || "NA"}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>

          <Grid container spacing={2} alignItems="flex-start" xs={12}>
            <Typography fontSize={17} fontWeight={"bold"} mb={2}>
              Load Test Agents
            </Typography>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                marginTop: "10px",
                borderRadius: "8px",
                backgroundColor: "#2065d10f",
                display: "flex",
                position: "relative",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {testDetails?.load_agent?.map((data, i) => (
                <Grid xs={12} container>
                  <Typography fontSize={16} mb={2}>
                    Load Agent - {i + 1}
                  </Typography>

                  <Grid xs={12} container direction={"row"}>
                    <Grid xs={12} md={3} marginBottom={2}>
                      <Typography fontSize={12}>Location</Typography>
                      <div
                        style={{
                          border: "1px solid #B8B8B8	",
                          padding: "8px",
                          borderRadius: "4px",
                          width: "80%",
                        }}
                      >
                        <Typography>{data?.location || "NA"}</Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={3} marginBottom={2}>
                      <Typography fontSize={12}>Number of Servers</Typography>
                      <div
                        style={{
                          border: "1px solid #B8B8B8	",
                          padding: "8px",
                          borderRadius: "4px",
                          width: "80%",
                        }}
                      >
                        <Typography>{data?.NumServers || "NA"}</Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={3} marginBottom={2}>
                      <Typography fontSize={12}>Server Size</Typography>
                      <div
                        style={{
                          border: "1px solid #B8B8B8	",
                          padding: "8px",
                          borderRadius: "4px",
                          width: "80%",
                        }}
                      >
                        <Typography>{data?.size || "NA"}</Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={3} marginBottom={2}>
                      <Typography fontSize={12}>Disk Size</Typography>
                      <div
                        style={{
                          border: "1px solid #B8B8B8	",
                          padding: "8px",
                          borderRadius: "4px",
                          width: "80%",
                        }}
                      >
                        <Typography>{data?.disk_size || "NA"}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container direction={"row"}>
                    <Grid xs={12} md={3} marginBottom={2}>
                      <Typography fontSize={12}>Security Group Id</Typography>
                      <div
                        style={{
                          border: "1px solid #B8B8B8	",
                          padding: "8px",
                          borderRadius: "4px",
                          width: "180%",
                        }}
                      >
                        <Typography>{data?.sec_group || "NA"}</Typography>
                      </div>
                    </Grid>
                    <Grid
                      xs={12}
                      md={3}
                      marginBottom={2}
                      style={{ marginLeft: "25%" }}
                    >
                      <Typography fontSize={12}>Subnet Id</Typography>
                      <div
                        style={{
                          border: "1px solid #B8B8B8	",
                          padding: "8px",
                          borderRadius: "4px",
                          width: "180%",
                        }}
                      >
                        <Typography>{data?.subnet_id || "NA"}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>

          <Grid container spacing={2} alignItems="flex-start" xs={12}>
            <Typography fontSize={17} fontWeight={"bold"} mb={2}>
              Advanced Cloud Options
            </Typography>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                marginTop: "10px",
                borderRadius: "8px",
                backgroundColor: "#2065d10f",
                display: "flex",
                position: "relative",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid xs={12} marginBottom={2}>
                <Typography fontSize={12}>User Properties File</Typography>
                <div
                  style={{
                    border: "1px solid #B8B8B8	",
                    padding: "8px",
                    borderRadius: "4px",
                    width: "95%",
                  }}
                >
                  <Typography>{testDetails?.user_property || "NA"}</Typography>
                </div>
              </Grid>
              <Typography fontSize={16} mb={2}>
                Selected Plugins
              </Typography>
              {!testDetails?.plugins && (
                <Grid xs={12} container>
                  <Typography fontSize={14}>{"No Plugins Found"}</Typography>
                </Grid>
              )}
              {testDetails?.plugins?.map((data, i) => (
                <Grid xs={12} container>
                  <Grid xs={12} container direction={"row"}>
                    <Grid xs={12} md={3} marginBottom={2}>
                      <Typography fontSize={12}>Name</Typography>
                      <Typography>{data?.name || "NA"}</Typography>
                    </Grid>
                    <Grid xs={12} md={3} marginBottom={2}>
                      <Typography fontSize={12}>Version</Typography>
                      <Typography>{data?.version || "NA"}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>
        </Stack>
      </Container>
    </>
  );
}
