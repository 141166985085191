// Subscriptions.js
import { React, useEffect, useState } from 'react';
import { Container, Paper, Typography, Button } from '@mui/material';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import PayButton from "./PayButton";
import { baseUrl,BasicMonthly,BasicYearly,PremiumMonthly,PremiumYearly,EnterpriseMonthly,EnterpriseYearly } from '../utils/constants';
import '../theme/popup.css';


const Subscriptions = () => {
  const [subscription, setSubscription] = useState('');
  const [isToggled, setIsToggled] = useState(true);
  const navigate = useNavigate();
  const userDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

  const handleTg = () => {
    setIsToggled(!isToggled);
  }

  
  const handleFreeCheckout = () => {
    axios
      .post(`${baseUrl}/stripe/free_sub`, {
        userId: userDetails.id,
        userEmail: userDetails.email,
      })
      .then((response) => {
        // if (response.data.url) {
          navigate('/checkout-success', { replace: true });
        // }
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    const fetchPSubscription = async () => {
      axios
        .get(`${baseUrl}/users/my-subscription`)
        .then((res) => {
          if (res && res.data.status === 200) {
            setSubscription(res.data.data);
          }
        })
        .catch((err) => {
          console.log('error');
        });
    };
    fetchPSubscription();
  }, []);

  const handleScroll = () => {

    window.scrollTo({ top: window.innerHeight, behavior: "smooth" })

  }

  return (
    <Container >
      {/* mycode */}
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "0", color:'#2065d1'}}>
        <h1>Get started for free</h1>
        {/* <p>hello world</p> */}
        <Button style={{ border: "2px solid #1F65D1", color: "#1F65D1" }} onClick={handleScroll} >Compare Plans</Button>
        <div style={{ marginTop: "-10px", display: "flex", alignItems: "center", gap: "10px", marginLeft:'60px', lineHeight:'70px'}}>
          <p>Month-to-Month</p>
          <label className="switch" htmlFor='u'>
            <input type="checkbox" checked={isToggled} onChange={handleTg} id='u' style={{color:'#1F65D1'}}/>
            <span className="slider" style={{ color:'#1F65D1'}} />
          </label>
          <p>Annual - Save up to 35%</p>
          {/* <p>{isToggled ? "on" : "off"}</p> */}
        </div>
      </div>
      {/* mycode  */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: 'inline-block', marginRight: '20px', marginLeft: "5px"}}>
          <Paper
            elevation={3}
            style={{
              height: '450px',
              width: '265px',
              padding: '30px',
              borderRadius: '20px',
              backgroundColor: '#f2f6fc',
              // marginLeft:'-110px'
            }}
          >
            <Typography variant="h3" style={{ fontSize: '38px', textAlign: 'center' }}>
              Free
            </Typography>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography variant="h3" style={{ fontSize: '38px', color: '#2065d1', textAlign: 'center' }}>
                $0
              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography style={{ fontSize: '18px', lineHeight: '1.5', fontWeight: 600 }}>
                1 Test <br />
                Test Length ≤ 1 Day <br />
                10 vCPUs <br />
                1 Team Member <br /><br /><br />
              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              {/* <Button variant="contained" color="primary">
            Subscribe
          </Button> */}
                <Button variant="contained" color="primary" disabled>Check out</Button>
            </div>
          </Paper>
        </div>

        <div style={{ display: 'inline-block', marginRight: '20px' }}>
          <Paper
            elevation={3}
            style={{
              height: '450px',
              width: '265px',
              padding: '30px',
              borderRadius: '20px',
              backgroundColor: '#f2f6fc',
            }}
          >
            <Typography variant="h3" style={{ fontSize: '38px', textAlign: 'center' }}>
              Basic
            </Typography>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography variant="h3" style={{ fontSize: '38px', color: '#2065d1', textAlign: 'center' }}>
                {isToggled ? "$65/Month " : "$99/Month "}
              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography style={{ fontSize: '18px', lineHeight: '1.5', fontWeight: 600 }}>
                Unlimited Tests <br />
                Unlimited Test Length<br />
                50 vCPUs<br />
                5 Team Member <br /><br /><br />
              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              {/* <Button variant="contained" color="primary">
            Subscribe
          </Button> */}
              <PayButton cartItems={{ plan: "Basic", amount: (isToggled ? 65 : 99), isToggled, price_id:(isToggled?BasicYearly:BasicMonthly), plan_type: (isToggled ? 'Yearly' : 'Monthly') }} />

            </div>
          </Paper>
        </div>

        <div style={{ display: 'inline-block', marginRight: '20px' }}>
          <Paper
            elevation={3}
            style={{
              height: '450px',
              width: '265px',
              padding: '30px',
              borderRadius: '20px',
              backgroundColor: '#f2f6fc',
            }}
          >
            <Typography variant="h3" style={{ fontSize: '38px', textAlign: 'center' }}>
              Premium
            </Typography>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography variant="h3" style={{ fontSize: '38px', color: '#2065d1', textAlign: 'center' }}>
                {isToggled ? "$149/Month " : "$199/Month "}

              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography style={{ fontSize: '18px', lineHeight: '1.5', fontWeight: 600 }}>
                Unlimited Tests <br />
                Unlimited Test Length <br />
                100 vCPUs<br />
                15 Team Members <br />
                Test Management <br /> <br />
              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              {/* <Button variant="contained" color="primary">
            Subscribe
          </Button> */}
              <PayButton cartItems={{ plan: "Premium", amount: (isToggled ? 149 : 199), isToggled, price_id:(isToggled?PremiumYearly:PremiumMonthly), plan_type: (isToggled ? 'Yearly' : 'Monthly')}} />

            </div>
          </Paper>
        </div>

        <div style={{ display: 'inline-block' }}>
          <Paper
            elevation={3}
            style={{
              height: '450px',
              width: '265px',
              padding: '30px',
              borderRadius: '20px',
              backgroundColor: '#f2f6fc',
            }}
          >
            <Typography variant="h3" style={{ fontSize: '38px', textAlign: 'center' }}>
              Enterprise
            </Typography>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography variant="h3" style={{ fontSize: '38px', color: '#2065d1', textAlign: 'center' }}>
                {isToggled ? "$399/Month " : "$499/Month "}

              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography style={{ fontSize: '18px', lineHeight: '1.5', fontWeight: 600 }}>
                Unlimited Tests <br />
                Unlimited Test Length <br />
                Unlimited vCPUs<br />
                30 Teams <br />
                Test Management <br />
              </Typography>
            </div>
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              {/* <Button variant="contained" color="primary">
            Subscribe
          </Button> */}
              <PayButton cartItems={{ plan: "Enterprise", amount: (isToggled ? 399 : 499), isToggled, price_id:(isToggled?EnterpriseYearly:EnterpriseMonthly), plan_type: (isToggled ? 'Yearly' : 'Monthly') }} />

            </div>
          </Paper>
        </div>
      </div>
      <h1 style={{ textAlign: "center", marginTop: "50px",color:'#1F65D1'}}>Compare Plans</h1>
      <div style={{ marginTop: "20px" }}>
        <TableContainer component={Paper} >
          <Table >
            <TableHead >
              <TableRow style={{ backgroundColor: "#1F65D1" }}>
                <TableCell style={{ color: "white" }}>Features</TableCell>
                <TableCell style={{ color: "white" }} >Free</TableCell>
                <TableCell style={{ color: "white" }}>Basic</TableCell>
                <TableCell style={{ color: "white" }}>Premium</TableCell>
                <TableCell style={{ color: "white" }}>Enterprise</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              <TableRow >
                <TableCell>Supported jmeter Test</TableCell>
                <TableCell>✓ </TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Number of Tests</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>Test Duration</TableCell>
                <TableCell>1 Day</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Virtual CPUs (vCPUs)</TableCell>
                <TableCell>10</TableCell>
                <TableCell>50</TableCell>
                <TableCell>100</TableCell>
                <TableCell>Unlimited</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>JMX File Limit </TableCell>
                <TableCell>Max File Size: 1MB</TableCell>
                <TableCell>Max File Size: 5MB</TableCell>
                <TableCell>Max File Size: 150MB</TableCell>
                <TableCell>Max File Size: 500MB</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Extra File(Data file, CSV) </TableCell>
                <TableCell>   Max File Size: 1MB
                  Max No. Files: 1</TableCell>
                <TableCell>Max File Size: 20 MB
                  Max No. Files: 5</TableCell>
                <TableCell>Max File Size: 300 MB
                  Max No. Files: 35</TableCell>
                <TableCell>Max File Size: 1000 MB
                  Max No. Files: 50
                </TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>Virtual Users</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
                <TableCell>Unlimited</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Max Load Region(LG's) </TableCell>
                <TableCell>1</TableCell>
                <TableCell>10</TableCell>
                <TableCell>50</TableCell>
                <TableCell>Unlimited</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>Data Retention </TableCell>
                <TableCell>45 days</TableCell>
                <TableCell>1 Year</TableCell>
                <TableCell> 2 Years</TableCell>
                <TableCell>5 Years</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Number of Team Members</TableCell>
                <TableCell>1</TableCell>
                <TableCell>5</TableCell>
                <TableCell>15</TableCell>
                <TableCell>30</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>Number of AWS Keys</TableCell>
                <TableCell>1</TableCell>
                <TableCell>1</TableCell>
                <TableCell>3</TableCell>
                <TableCell>5</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Number of Concurrent Tests
                </TableCell>
                <TableCell>1</TableCell>
                <TableCell>3</TableCell>
                <TableCell>5</TableCell>
                <TableCell>10</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>Test ReRun
                </TableCell>
                <TableCell> </TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Merged Results
                </TableCell>
                <TableCell> </TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓
                </TableCell>
                <TableCell>✓
                </TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>View JMeter Dashboard
                </TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Download Report
                </TableCell>
                <TableCell> </TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
                <TableCell>✓</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow >
                <TableCell>Support
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Call</TableCell>
                <TableCell>Call</TableCell>
              </TableRow>

            </TableBody>
            <TableBody>

              <TableRow style={{backgroundColor:'#f2f6fc'}}>
                <TableCell>Cost*
                </TableCell>
                <TableCell>14 days Free</TableCell>
                <TableCell>$99/Monthly </TableCell>
                <TableCell>$199/Monthly</TableCell>
                <TableCell> $499/Monthly</TableCell>
              </TableRow>

            </TableBody>

          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

export default Subscriptions;
