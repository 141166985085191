import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
// @mui
import {  Stack, IconButton, InputAdornment, TextField, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { baseUrl, defaultToastProps } from '../../utils/constants';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  function handleLoginClick() {
    axios
      .post(`${baseUrl}/users/login`, {
        email,
        password,
      })
      .then((res) => {
        const token = res.data?.data?.token;

        if (token) {
          axios.defaults.headers.common.token = token;
          sessionStorage.setItem('authUser', JSON.stringify(res.data.data));
          navigate('/dashboard', { replace: true });
        } else {
          // If the response does not contain a valid token, it means the login failed
          toast.error('Invalid email or password. Please enter valid credentials.', defaultToastProps);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle other errors (e.g., network issues)
        if (error.response) {
          // If the server responds with an error, check the error status and display appropriate messages
          if (error.response.status === 400) {
            toast.error('Invalid password. Please enter a valid password.', defaultToastProps);
          } else if (error.response.status === 401) {
            toast.error('Invalid email format. Please enter a valid email address.', defaultToastProps);
          } else {
            toast.error('An error occurred while logging in', defaultToastProps);
          }
        } else {
          toast.error('An error occurred while logging in', defaultToastProps);
        }
      });
  }

  const handleFormValidation = () => {
    let errorMessage;
    if (!email) {
      errorMessage = 'Please enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errorMessage = 'Please enter a valid email';
    } else if (!password) {
      errorMessage = 'Please enter Password';
    }
    if (errorMessage) {
      toast.warn(errorMessage, defaultToastProps);
    } else {
      handleLoginClick();
    }
  };
  const handleCreateClick = () => {
    navigate('/Forget-Password', { replace: true });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          type="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        
      </Stack>
      <div style={{ textAlign: 'center'}}>
      <Link variant="subtitle2" onClick={() => handleCreateClick()}>
                Forget Password?
              </Link>
  </div>

  <div style={{marginTop:'20px'}}>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleFormValidation}>
        Login
      </LoadingButton>
      </div>

    </>
  );
}
