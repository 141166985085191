import React, { useState } from 'react';
import { Tab, Tabs, Box, Typography, Paper } from '@mui/material';
import AwsKeys from './awsKey';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function UserPage() {
  return (
    <div>
      <Paper style={{ width: '96%' }}>
        <Tabs indicatorColor="primary" textColor="primary" centered>
          <Tab label="AWS Key/Secret" />
        </Tabs>

        <TabPanel>
          <AwsKeys />
        </TabPanel>
      </Paper>
    </div>
  );
}

export default UserPage;

// import React, { useState } from 'react';
// import { Tab, Tabs, Box, Typography, Paper } from '@mui/material';
// import AwsCrossAccount from './awsCross';
// import AwsKeys from './awsKey';

// function TabPanel(props) {
//   const { children, value, index } = props;

//   return (
//     <Typography component="div" role="tabpanel" hidden={value !== index}>
//       {value === index && <Box p={3}>{children}</Box>}
//     </Typography>
//   );
// }

// function UserPage() {
//   const [value, setValue] = useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <div>
//       <Paper style={{ width: '96%' }}>
//         <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
//           <Tab label="AWS Cross Account Role" />
//           <Tab label="AWS Key/Secret" />
//         </Tabs>
//         <TabPanel value={value} index={0}>
//           <AwsCrossAccount />
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           <AwsKeys />
//         </TabPanel>
//       </Paper>
//     </div>
//   );
// }

// export default UserPage;
