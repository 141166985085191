import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { toast } from 'react-toastify';
import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';
import LinearProgress from '@mui/material/LinearProgress';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/User';
// mock
import { baseUrl, userSessionData } from '../utils/constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Test Name', label: 'Test Name', alignRight: false },
  { id: 'User', label: 'User', alignRight: false },
  { id: 'Start Time', label: 'Start Time', alignRight: false },
  { id: 'Completed Time', label: 'Completed Time', alignRight: false },
  { id: 'Success Rate', label: 'Success Rate', alignRight: false },
  { id: 'HTML Report', label: 'HTML Report', alignRight: false },
  { id: 'View Details', label: 'View Details', alignRight: false },
  { id: 'Re-Run', label: 'Re-Run', alignRight: true },
  { id: 'Cancel', label: 'Cancel', alignRight: true },

];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ProjectPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('agent_startedat');

  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const user = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleCancelTest = (id) => {
    console.log('inside cancel test');
    axios
      .get(`${baseUrl}/test/cancel/${id}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          console.log(res.data);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log('error');
      });
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const navigate = useNavigate();
  const handleCreateClick = (data) => {
    if(data && user.plan==='Free'){
      toast.warn(`Please upgrade the Subscription to rerun the test.`);
      return;
    }
    navigate('/tests/test-form', { replace: false, state: { data } });
  };

  const fetchData = async (offset, limit) => {
    const endpoints = user?.role === 'admin' ? 'all' : 'my-test';
    axios
      .get(`${baseUrl}/test/${endpoints}?limt=${limit}&offset=${offset}`)
      .then((res) => {
        if (res && res.data.status === 200) {
          setRows(res.data.data.list);
          setTotalCount(Number(res.data.data?.list?.length));
          // setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('error');
        // setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchData(offset, limit);
  }, [limit, offset]);

  return (
    <>
      <Helmet>
        <title> Test</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Test
          </Typography>
          {userSessionData()?.role !== 'admin' && (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleCreateClick()}
            >
              Create New Test
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  numSelected={0}
                  onRequestSort={handleRequestSort}
                  hideCheckBox
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const startDate = new Date(row?.agent_startedat).toLocaleString();
                    const completeDate = new Date(row?.completedat).toLocaleString();
                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={0}>
                            <Typography variant="subtitle2" noWrap>
                              {row.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{row?.email}</TableCell>

                        <TableCell align="left">{row?.agent_startedat ? startDate : 'NA'}</TableCell>

                        <TableCell align="left">{row?.completedat ? completeDate : 'NA'}</TableCell>
                        <TableCell align="left">
                          {' '}
                          {row?.completedat || row?.cancelledat ? (
                            row.success_rate ? (
                              row.success_rate
                            ) : (
                              'NA'
                            )
                          ) : (
                            <LinearProgress variant="determinate" value={progress} />
                            )}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="outlined"
                            onClick={() =>
                              navigate(`/tests/${row?.id}/html-report`, { replace: false, state: { row } })
                            }
                            disabled={row && !row.report_html}
                          >
                            View HTML
                          </Button>
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="outlined"
                            onClick={() => navigate(`/tests/${row?.id}/details`, { replace: false, state: { row } })}
                          >
                            View
                          </Button>
                        </TableCell>

                        <TableCell align="right">
                    {user?.role !== 'admin' && (
                      <>
                          <IconButton
                            size="large"
                            sx={{ color: 'error.main' }}
                            onClick={() => handleCreateClick(row)}
                          >
                            <Iconify icon={'mdi:restart'} />
                          </IconButton>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {user?.role !== 'admin' && (
                      <>
                          <IconButton
                            aria-label="Cancel Test"
                            onClick={() => handleCancelTest(row?.id)}
                            disabled={row?.cancelledat || row?.completedat}
                          >
                            <CancelIcon />
                          </IconButton>
                     
                      </>
                    )}
                  </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <IconButton>
            <Iconify icon={'eva:edit-fill'} />
          </IconButton>
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <IconButton>
            <Iconify icon={'eva:trash-2-outline'} />
          </IconButton>
        </MenuItem>
      </Popover>
    </>
  );
}
