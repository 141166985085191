import axios from "axios";
import { Button } from '@mui/material';

import { baseUrl } from '../utils/constants';

const PayButton = ({ cartItems }) => {
    const userDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));
  const handleCheckout = () => {
    axios
      .post(`${baseUrl}/users/create-checkout-session`, {
        cartItems,
        userId: userDetails.id,
        userEmail: userDetails.email,

      })
      .then((response) => {
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => handleCheckout()} disabled={cartItems?.subscription && cartItems?.subscription?.id}>Check out</Button>
    </>
  );
};

export default PayButton;