import React, { useEffect, useState } from 'react';
import Axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router'; // Import useNavigate
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { IconButton, Link, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon

import { baseUrl, defaultToastProps } from '../utils/constants';

export default function AwsKeys() {
  const [showInstructions, setShowInstructions] = useState(false);
  const [awsKey, setAwsKey] = useState('');
  const [awsSecret, setAwsSecret] = useState('');
  const awsDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));
  const navigate = useNavigate(); // Use useNavigate to get the navigation function
  const [awsKeyApi, setAwsKeyApi] = useState('');
  const [awsKeyId, setAwsKeyId] = useState('');

  
  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  const jsonContent = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Action": [
                "iam:GetUser",
                "iam:SimulatePrincipalPolicy",
                "iam:PassRole",
                "iam:CreateServiceLinkedRole",
                "ec2:CreateTags",
                "ec2:DescribeInstances",
                "ec2:DescribeSpotPriceHistory",
                "ec2:DescribeSpotInstanceRequests",
                "ec2:RequestSpotInstances",
                "ec2:RunInstances",
                "ec2:StartInstances",
                "ec2:DescribeAccountAttributes",
                "ec2:DescribeSubnets",
                "ec2:DescribeSecurityGroups"
            ],
            "Effect": "Allow",
            "Resource": [
                "*"
            ]
        },
        {
            "Action": [
                "ec2:StopInstances",
                "ec2:TerminateInstances"
            ],
            "Effect": "Allow",
            "Resource": "*",
            "Condition": {
                "StringEquals": {
                    "ec2:ResourceTag/redline": "T"
                }
            }
        }
    ]
  }`;

  const handleRemoveKey = () => {
    // Use Axios to call the API for removing the AWS key
    Axios.delete(`${baseUrl}/aws/remove/${awsKeyId}`)
      .then((response) => {
        console.log('AWS Key removed successfully', response.data);
        toast.success('AWS Key removed successfully', defaultToastProps);
    window.location.reload();
        // Optionally, update the state or perform any other actions after removal
      })
      .catch((error) => {
        console.error('Error removing AWS Key', error);
        toast.error('Error removing AWS Key', defaultToastProps);
      });
  };

  const handleAddKeyPair = () => {
    if (!awsKey || !awsSecret) {
      // Show an error toast if either AWS Key or AWS Secret is missing
      toast.error('Both AWS Key and AWS Secret are required', defaultToastProps);
      return;
    }

    const postData = {
      aws_key: awsKey,
      aws_secret: awsSecret,
      user_id: awsDetails?.id,
    };
    Axios.post(`${baseUrl}/aws/add-aws`, postData)
      .then((response) => {
        console.log('Data posted successfully', response.data);
        toast.success('Aws added successfully', defaultToastProps);

        // Redirect to /dashboard
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Error posting data', error);
        if(error.response.data.data.message)
        toast.error(error.response.data.data.message, defaultToastProps);
        else
        toast.error('Aws not added', defaultToastProps);
      });
  };

  useEffect(() => {
    Axios.get(`${baseUrl}/aws/primary-aws`)
      .then((response) => {
        const primaryAwsKey = response.data.aws_key;
        setAwsKeyApi(primaryAwsKey); // Set the API key
        setAwsKeyId(response.data.id)
      })
      .catch((error) => {
        console.error('Error fetching primary AWS key', error);
        // toast.error('Error fetching AWS key', defaultToastProps);
      });
  }, []);

  return (
    <div>
      <Button
        variant="outlined"
        onClick={toggleInstructions} // Call the toggle function when the button is clicked
        // style={{ marginTop: 10 }}
      >
        Toggle instruction
      </Button>

      <div>
        {showInstructions && (
          <div>
            <Paper
              style={{
                // marginTop: '20px',
                borderRadius: '8px',
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
              }}
            >
              <Typography style={{ fontSize: '15px', padding: '20px' }}>
                1. Login to the AWS IAM console at{' '}
                <Link href="https://console.aws.amazon.com/iam/home" target="_blank" rel="noopener">
                  https://console.aws.amazon.com/iam/home
                </Link>
                .<br />
                2. Click the{' '}
                <Link href="<YOUR_POLICIES_LINK_HERE>" target="_blank" rel="noopener">
                  “Policies”
                </Link>{' '}
                link, then the{' '}
                <Link href="<YOUR_ROLES_LINK_HERE>" target="_blank" rel="noopener">
                  “Create Role”
                </Link>{' '}
                button.
                <br />
                3. Select the “JSON” tab, then paste the following Policy JSON document.
                <br />
              </Typography>
              <Paper
                elevation={3}
                style={{
                  padding: '20px',
                  marginTop: '20px',
                  borderRadius: '8px',
                  backgroundColor: '#f1f5ff',
                  display: 'flex',
                  position: 'relative',
                  flexDirection: 'column',
                  margin: '20px',
                }}
              >
                <pre>{jsonContent}</pre>
              </Paper>
              <div>
                <Typography>
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    The iam:GetUser, iam:SimulatePrincipalPolicy, iam:PassRole actions are not required, but allow
                    Redline to validate your policy.
                  </span>{' '}
                  <br />
                  <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    iam:PassRole <br />{' '}
                  </span>
                  Used for extended security to limit termination to instance-profiles. <br />
                  You will need to also change the condition for stop and terminate to specify the instance profile with
                  privileges. <br />
                  "ec2:InstanceProfile": "arn:aws:iam::ACCOUNT:instance-profile/NAME_OF_INSTANCE_PROFILE" <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    {' '}
                    CreateServiceLinkedRole <br />{' '}
                  </span>
                  May be needed if you haven’t used spot instances before and allows AWS to launch instances on your
                  behalf. <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    {' '}
                    SimulatePrincipalPolicy <br />{' '}
                  </span>
                  Used to test a policy for permissions <br />
                  <br />
                  The actions following the skipped line are not essential, but recommended. <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    {' '}
                    DescribeAccountAttributes <br />{' '}
                  </span>
                  Used to determine account settings to help us prevent you from starting servers that are incompatible
                  with your AWS account settings <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    {' '}
                    DescribeSubnets <br />{' '}
                  </span>
                  Used to show list of subnets in a drop down <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    {' '}
                    DescribeSecurityGroups <br />{' '}
                  </span>
                  Used to show your list of security groups when creating a test <br />
                  <br />
                  The actions in the second action step are not required, but are limited to only resources tagged with
                  the redline tag. <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    {' '}
                    StopInstances <br />{' '}
                  </span>
                  Used to stop instances <br />
                  <span style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    {' '}
                    TerminateInstances <br />{' '}
                  </span>
                  Used to terminate instances from the UI or automated if we find unresponsive redline13 load agent{' '}
                  <br />
                  4. Click "Review Policy" <br />
                  5. Enter a policy name (e.g. “RedlineAccess”). Optionally a description. <br />
                  6. Click "Create Policy". You should see 'Policy Name' has been created. <br />
                  7. Click the{' '}
                  <Link href="<YOUR_ROLES_LINK_HERE>" target="_blank" rel="noopener">
                    “Roles”
                  </Link>{' '}
                  link, then the “Create Role” button. <br />
                  8. Select the “Another AWS Account” option. <br />
                  9. Enter Account ID “635144173025”, check “Require external ID”, and enter the External ID
                  (h6MxuGsxWhmBVTOt). <br />
                  10. On the next step, Select the policy that you created above. <br />
                  11. On the Review page, enter a Role Name (E.g. “RedlineAccess”), then click “Create Role”. <br />
                  12. On the roles page select (click into) your newly created role <br />
                  13. Copy the Role ARN and enter into the RedLine13 form, then submit the form. <br />
                </Typography>
              </div>
            </Paper>
          </div>
        )}
      </div>
      {awsKeyApi && (
        <div style={{ marginTop: 24, display: 'flex', alignItems:"center" }}>
          {/* <TextField
            id="awsKeyApi"
            variant="outlined"
            size="small"
            style={{ width: '25%' }}
            InputProps={{
              style: { borderColor: '#3367ff' },
            }}
            value={awsKeyApi}
            disabled
          /> */}
          {/* <label htmlFor="apikey" style={{width:"25%", display:"flexs"}}>
            API Key  </label>  */}
          {/* <p id='apikey'>
           {awsKeyApi} 
          </p> */}
          <input type='text' placeholder='API Key' disabled style={{border:"none", backgroundColor:"white"}}/>
          <input type='text' id="apikey" disabled placeholder={awsKeyApi} style={{border:"none",width:"250px", backgroundColor:"white"}}/>
          <IconButton color="secondary" onClick={handleRemoveKey}>
            <DeleteIcon />
          </IconButton>

        </div>
      )}
      <div style={{ marginTop: 24 }}>
        <TextField
          id="awsKey"
          label="AWS Key"
          variant="outlined"
          size="small"
          style={{ width: '25%' }}
          InputProps={{
            style: { borderColor: '#3367ff' },
          }}
          value={awsKey}
          onChange={(e) => setAwsKey(e.target.value)}
        />
        <TextField
          id="awsSecret"
          label="AWS Secret"
          variant="outlined"
          size="small"
          style={{ marginLeft: 50, width: '25%' }}
          InputProps={{
            style: { borderColor: '#3367ff' },
          }}
          value={awsSecret}
          onChange={(e) => setAwsSecret(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          size="small"
          style={{ marginLeft: 50, width: '25%' }}
          InputProps={{
            style: { borderColor: '#3367ff' }, // Change the border color here
          }}
        />
      </div>
      <div>
        <Button variant="contained" style={{ marginTop: 10 }} onClick={handleAddKeyPair}>
          Add Key Pair
        </Button>
      </div>
    </div>
  );
}
