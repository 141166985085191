import { Navigate, Route, useRoutes } from 'react-router-dom';
import axios from 'axios';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import ReportPage from './pages/ReportPage';
import ProjectPage from './pages/Projects/ProjectPage';
import TestPage from './pages/TestPage';
import TestForm from './pages/TestForm';
import DashboardAppPage from './pages/Dashboard/DashboardAppPage';
import User from './pages/User';
import LoginPage from './pages/Auth/Login';
import SignUpPage from './pages/Auth/SignUp';
import SimpleLayout from './layouts/simple/SimpleLayout';
import AwsPage from './pages/awsKeysTab';
import TestDetails from './pages/TestDetails';
import HTMLReportPage from './pages/HTMLPage';
import Subscriptions from './pages/Subscriptions';
import ChangePassword from './pages/ChangePassword';
import ForgetPassword from './pages/Auth/ForgetPassword';
import CheckoutSuccess from './pages/CheckoutSuccess';
// ----------------------------------------------------------------------

export default function Router() {
  const user = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));
  if (user) {
    axios.defaults.headers.common.token = user.token;
  }
  const routes = useRoutes([
    {
      path: '/',
      children: [{ element: <Navigate to={user ? '/dashboard' : '/login'} />, index: true }],
    },
    {
      path: '/login',
      element: <LoginPage />, // This is the login page component
      children: [{ element: <Navigate to={user ? '/dashboard' : '/login'} />, index: true }],
    },
    {
      path: '/Signup',
      element: <SignUpPage />,
    },
    {
      path: '/Forget-Password',
      element: <ForgetPassword />,
    },
    {
      path: '/dashboard',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <DashboardAppPage /> }],
    },
    {
      path: '/projects',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <ProjectPage /> },
      { path: '/projects/:userId', element: <ProjectPage /> }
    ],
    },
    {
      path: '/tests',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [
        { path: '', element: <TestPage /> },
        { path: '/tests/test-form', element: <TestForm /> },
        { path: '/tests/:id/details', element: <TestDetails /> },
        { path: '/tests/:id/html-report', element: <HTMLReportPage /> },
      ],
    },
    {
      path: '/AwsSetup',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <AwsPage /> }],
    },
    {
      path: '/Subscriptions',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <Subscriptions /> }],
    },
    {
      path: '/checkout-success',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <CheckoutSuccess /> }],
    },
    {
      path: '/Change-Password',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <ChangePassword /> }],
    },
   
    // {
    //   path: '/test-form',
    //   element: <ProtectedRoute element={<DashboardLayout />} />,
    //   children: [],
    // },
    {
      path: '/reports',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <ReportPage /> }],
    },
    {
      path: '/users',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [{ path: '', element: <User /> }],
    },

    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     // { path: '404', element: < /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
    {
      path: '/404',
      element: <SimpleLayout />,
    },
  ]);

  return routes;
}

const ProtectedRoute = ({ element }) => {
  const user = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

  if (user) {
    return element;
  }
  return <Navigate to="/login" />;
};
