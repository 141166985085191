import React from 'react';
import { ClipLoader } from 'react-spinners';

export default function LoadIndicator({
  color = '#103996',
  loading = true,
  override = { alignSelf: 'center' },
  size = 150,
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 60,
      }}
    >
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
