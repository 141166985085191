import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, TextField, Stack } from '@mui/material';
// hooks
import { baseUrl, defaultToastProps } from '../../utils/constants';
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo/index';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate('/Signup', { replace: true });
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true);

      // Send a PUT request to the forgot-password endpoint with the email in the request body
      const response = await fetch(`${baseUrl}/users/forgot-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        toast.success('Email Sent successfully', defaultToastProps);
        navigate('/login', { replace: true });
        // Success: handle the success case (e.g., show a success message, redirect, etc.)
      } else {
        toast.warn('Error Sending Email', defaultToastProps);
        // Error: handle the error case (e.g., show an error message)
      }
    } catch (error) {
      console.error('Error sending forgot password request:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title> Login | BaseLine11 </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Forget Password
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" onClick={() => handleCreateClick()}>
                Get started
              </Link>
            </Typography>
            <Stack spacing={3} style={{ marginTop: '-20px' }}>
              <TextField
                name="email"
                type="email"
                label="Please enter your registered email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div style={{ marginTop: '20px' }}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={handleFormSubmit}
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </div>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
