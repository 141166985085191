import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Button, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { baseUrl, defaultToastProps } from '../utils/constants';

// Define the styled component outside the component
const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d9dd',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
}));

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Check if passwords match

    if (!newPassword || !confirmPassword) {
      toast.warn('Please fill all inputs', defaultToastProps);
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.warn('Password dont match ', defaultToastProps);
      return;
    }
  
    // Assuming you have the user email in sessionStorage
    const user = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

    // Log the user email to the console
    console.log('User Email:', user.email);
  
    // Make API call to change password
    fetch(`${baseUrl}/users/change-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user.email,
        password: newPassword,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle API response as needed
        toast.success('Password changed successfully', defaultToastProps);
        navigate('/login', { replace: true });
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  

  return (
    <Container style={{ marginLeft: '17%' }}>
      <div>
        <Typography variant="h3">Change Password</Typography>
        <Paper
          elevation={3}
          style={{
            height: '300px',
            width: '450px',
            padding: '30px',
            borderRadius: '20px',
            backgroundColor: '#f2f6fc',
            marginTop: '20px',
          }}
        >
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <CustomTextField
              type="password"
              label="New Password"
              size="small"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputLabelProps={{ style: { color: 'black' } }}
              style={{ width: '60%', marginTop: '20px' }}
            />
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <CustomTextField
              type="password"
              label="Confirm Password"
              size="small"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputLabelProps={{ style: { color: 'black' } }}
              style={{ width: '60%', marginTop: '20px' }}
            />
          </div>
          <div style={{ textAlign: 'center', marginTop: '40px' }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Paper>
      </div>
    </Container>
  );
};

export default ChangePassword;