export const baseUrl = 'http://node.baseline11.com/api/v1';
//export const baseUrl = 'http://localhost:8080/api/v1';

export const BasicMonthly='price_1OdBAJSFpZ2yZVOD2OWrD01k'
export const BasicYearly='price_1OdCKUSFpZ2yZVODiD5SGWME'
export const PremiumMonthly='price_1OdC7sSFpZ2yZVODzCJgElU3'
export const PremiumYearly='price_1OdCPCSFpZ2yZVODMTc1eSFB'
export const EnterpriseMonthly='price_1OdCHXSFpZ2yZVODnPg2tIVI'
export const EnterpriseYearly='price_1OdCXUSFpZ2yZVODlQ8Lgi7D'



export const defaultToastProps = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const userSessionData = () =>
  sessionStorage.getItem('authUser') ? JSON.parse(sessionStorage.getItem('authUser')) : {};
