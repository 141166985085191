import React, { useEffect, useState } from 'react';
import axios from 'axios';

import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import { Helmet } from 'react-helmet-async';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CloseIcon from '@mui/icons-material/Close';

import {
  styled,
  Container,
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  Tooltip,
  Paper,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Snackbar,
  Alert,
  Popover,
  Box
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Nav from '../layouts/dashboard/nav';
import Header from '../layouts/dashboard/header';
import { baseUrl, defaultToastProps } from '../utils/constants';
import useResponsive from '../hooks/useResponsive';
import LoadIndicator from '../components/LoadIndicator';
import {plan} from '../utils/plans';

// ----------------------------------------------------------------------

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    border-color: #d4d9dd;
    &:hover {
      border-color: ${blue[800]};
    }
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
    &:focus-visible {
      outline: 0;
    }
  `
);

const CustomAutoComplete = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d9dd',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
}));

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d9dd',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
}));

const defaultRowValue = {
  location: '',
  numberOfServers: '1',
  serverSize: '',
  cpuSize:'',
  instanceType: 'Demand',
  instanceAmount: '',
  diskSize: '16',
  subnetId: '',
  securityGroupId: '',
  associatePublicIP: '',
};

export default function TestForm(props) {
  const userDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));
  const locationState = useLocation();
  const navigate = useNavigate();
  const testData = locationState?.state?.data;

  const [jmxFile, setJmxFile] = useState('');
  const [keyValuePairs, setKeyValuePairs] = useState([]);
  const [optionalDescription, setOptionalDescription] = useState('');
  const [extraFiles, setExtraFiles] = useState([]);
  const [userPropFiles, setUserPropFiles] = useState('');
  const [jmeterVersion, setJmeterVersion] = useState('');

  // Define state variables for Select components
  const [testName, setTestName] = useState('');
  const [location, setLocation] = useState([]);
  const [size, setSize] = useState([[]]);
  const [subnetId, setSubnetId] = useState([[]]);
  const [securityGroupId, setSecurityGroupId] = useState([[]]);
  const [loadAgentsArray, setLoadAgentsArray] = useState([{ ...defaultRowValue }]);
  const [plugins, setPlugins] = useState([]);
  const [selectedPlugins, setSelectedPlugins] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [searchInput, setSearchInput] = useState(''); // State for search input
  const [filteredPlugins, setFilteredPlugins] = useState([]);
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isFileTypeAlertOpen, setFileTypeAlertOpen] = useState(false);
  const [fileTypeAlertMessage, setFileTypeAlertMessage] = useState('');
  const [isAwsSet, setIsAwsSet] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isBlurActive, setIsBlurActive] = useState(false);
  const [extraFilesCount, setExtraFilesCount] = useState(0);

  const mdDown = useResponsive('down', 'md');
  const handleOpenPopover = () => {
    setIsPopoverOpen(true);
    setIsBlurActive(true); // Activate blur when the popover opens
  };
  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setIsBlurActive(false); // Deactivate blur when the popover closes
  };
  const handleBackButtonClick = () => {
    navigate('/tests', { replace: false, state: { data: testData } });
  };
  // ...
  const openFileTypeAlert = (message) => {
    setFileTypeAlertMessage(message);
    setFileTypeAlertOpen(true);
  };

  const closeFileTypeAlert = () => {
    setFileTypeAlertOpen(false);
    setFileTypeAlertMessage('');
  };

  const handleExtraFileUpload = (event, index) => {
    const selectedFiles = Array.from(event.target.files);
  
    if (selectedFiles?.length > 0) {
      const allowedFileTypes = [
        'text/csv',
        'text/plain', // for TXT files
        'application/vnd.ms-excel', // for XLS files
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // for XLSX files (optional)
        'application/octet-stream' /* Add more allowed types if needed */,
        'image/jpeg',
        'image/png',
        'image/jpg',
        'image/gif',
      ];
  
      selectedFiles?.forEach((file, i) => {
        if (!allowedFileTypes.includes(file.type)) {
          toast.warning(
            "Only CSV, TXT, XLS, XLSX, JPEG, PNG, JPG and GIF files are allowed for upload.",
            defaultToastProps
          );
          return;
        }
      });
     
      
      setExtraFiles(
        extraFiles?.map((item, i) =>
          i === index ? { file: selectedFiles[0] , split: item?.split} : item
        )
      );
    
    }
  };

  const handleToggleSplit = (index) => {
    setExtraFiles(
      extraFiles?.map((item, i) =>
        i === index ? { file: item.file, split: !item?.split } : item
      )
    );
  }

  const handleJmxFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.name.toLowerCase().endsWith('.jmx')) {
      setJmxFile(file);

      const reader = new FileReader();

      reader.onload = async (e) => {
        const jmxContent = e.target.result;

        // Extract content inside <ThreadGroup> ... </ThreadGroup> tags
        const threadGroupRegex = /<ThreadGroup[^>]*>[\s\S]*?<\/ThreadGroup>/g;
        const threadGroupMatches = [...jmxContent.matchAll(threadGroupRegex)];

        if (threadGroupMatches?.length > 0) {
          const threadGroupContent = threadGroupMatches[0][0];

          // Replace &quot; with regular double quotes
          const cleanedContent = threadGroupContent.replace(/&quot;/g, '"');

          // Now, parse the content inside ThreadGroup for ${...} patterns with dynamic letters
          const regex = /\${__([A-Za-z]+)\(([^,]+),([^)]+)\)}/g;
          const matches = [...cleanedContent.matchAll(regex)];

          if (matches?.length > 0) {
            const newKeyValuePairs = matches?.map((match) => {
              const key = match[2];
              const value = match[3].replace(/"/g, ''); // Remove double quotes

              // Check if the 'value' contains any numbers
              if (!/\d/.test(value)) {
                // If it doesn't contain numbers, return a blank string
                return { key, value: '' };
              }

              return { key, value };
            });
            setKeyValuePairs(newKeyValuePairs);
          }
        }
      };

      reader.readAsText(file);
    } else {
      // Display an error or notify the user that only JMX files are allowed
      toast.warning('Only JMX files are allowed for upload.', defaultToastProps);
    }
  };

  const handleRemoveExtraFile = (index) => {
    setExtraFiles(
      extraFiles.filter((_, i) => i !== index)
    );
    setExtraFilesCount(extraFilesCount - 1);
  };
  const handleAddExtraFile = () => {
    setExtraFilesCount(extraFilesCount + 1);
    setExtraFiles(extraFiles.concat({"file":null, "split": false})); 
  };



  const handleLoadAgentsClick = () => {
    setLoadAgentsArray((prevState) => {
      if (prevState) {
        return [...prevState, { ...defaultRowValue }];
      }
      return [{ ...defaultRowValue }];
    });
  };

  const handleDeleteForm = (deleteIndex) => {
    setLoadAgentsArray((loadAgents) => {
      let row = loadAgents;
      if (loadAgents?.length > 1) row = row.filter((_, index) => index !== deleteIndex);
      return row;
    });
  };
  const handleDeletePlugin = (deleteIndex) => {
    setSelectedPlugins((plugins) => {
      let row = plugins;
      row = row.filter((_, index) => index !== deleteIndex);
      return row;
    });
  };

  const fetchProjects = async () => {
    axios
      .get(`${baseUrl}/projects/my-project`)
      .then((res) => {
        if (res && res.data.status === 200) {
          setProjects(res.data.data.list);
          // setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('error');
        // setShowLoader(false);
      });
  };
  const uniqueLocations = new Set();
  let cupNum=0

  const handleValidation = () => {
    loadAgentsArray.forEach(item => {
      uniqueLocations.add(item.location);
      cupNum+=Number(item.cpuSize)
    });
    console.log("size----", cupNum)
   
    // Check if testName is empty
    console.log("Plan-----",plan[userDetails.plan],uniqueLocations.size);
    if(plan[userDetails.plan].region<uniqueLocations.size){
      toast.warn(`You have ${plan[userDetails.plan].region} locations as per you plan`);
      return; // Don't proceed with the API call
    }
    if(plan[userDetails.plan].cpu<cupNum){
      toast.warn(`You have ${plan[userDetails.plan].cpu} vCPUs as per you plan`);
      return; // Don't proceed with the API call
    }
    if (!testName) {
      toast.warn('Please Enter Test Name', defaultToastProps);
      return; // Don't proceed with the API call
    }
    if (!project) {
      toast.warn('Please choose a project', defaultToastProps);
      return; // Don't proceed with the API call
    }

    // Check if a JMX file is not selected
    if (typeof jmxFile !== 'object' && typeof jmxFile !== 'string') {
      toast.warn('Please select a JMX file', defaultToastProps);
      return; // Don't proceed with the API call
    }

    // Check if at least one load agent is added
    if (loadAgentsArray.length === 0) {
      toast.warn('Please add at least one load agent', defaultToastProps);
      return; // Don't proceed with the API call
    }

    // Check if JMeter version is not selected
    if (!jmeterVersion) {
      toast.warn('Please select a JMeter version', defaultToastProps);
      return; // Don't proceed with the API call
    }
    if(userDetails.valid_till && new Date(userDetails.valid_till)<new Date()){
      console.log("Plan expired",userDetails.valid_till);
      handleOpenPopover();
      return; // Don't proceed with the API call
    }
    const isLoadAgentInfoValid = loadAgentsArray.every((agent) => {
      return (
        agent.location && // Check if location is selected
        agent.numberOfServers && // Check if the number of servers is entered
        agent.serverSize && // Check if the server size is selected
        agent.diskSize && // Check if the disk size is entered
        agent.subnetId && // Check if subnet ID is selected
        agent.securityGroupId // Check if security group ID is selected
      );
    });

    if (!isLoadAgentInfoValid) {
      toast.warn('Please fill in all load agent details', defaultToastProps);
      return;
    }

    // All validation passed, proceed with API call
    handleUploadfiles();
  };

  const handleUploadfiles = () => {
    setShowLoader(true);
    const formData = new FormData();
    const uploadFileArray = [];
    if (typeof jmxFile !== 'string') {
      formData.append('file', jmxFile);
      uploadFileArray.push('jmx');
    }
    if (typeof userPropFiles !== 'string') {
      formData.append('file', userPropFiles);
      uploadFileArray.push('user_property');
    }
    const uploadObject = {};

    if (uploadFileArray?.length > 0) {
      axios
        .post(`${baseUrl}/upload/test-files`, formData)
        .then((response) => {
          const filePaths = response.data.output;
          const jmxIndex = uploadFileArray.indexOf('jmx');
          const userPropIndex = uploadFileArray.indexOf('user_property');
          if (jmxIndex >= 0) {
            uploadObject.jmx = filePaths?.[jmxIndex]?.location;
          }
          if (userPropIndex >= 0) {
            uploadObject.user_property = filePaths?.[userPropIndex]?.location;
          }
          handleUploadExtrafiles(uploadObject);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setShowLoader(false);
          toast.warn('Error Uploading Files, Please try again later.', defaultToastProps);
        });
    } else {
      handleCreateTest(uploadObject);
    }
  };

  const handleUploadExtrafiles = (uploadObject) => {
    setShowLoader(true);
    const formData = new FormData();

    if (typeof extraFiles !== 'string') {
      extraFiles?.forEach((extraFile) => {
        formData.append('file', extraFile.file);
      });
    } else {
      handleCreateTest(uploadObject);
      return;
    }
    const finalUploadObject = { ...uploadObject };

    axios
      .post(`${baseUrl}/upload/test-files`, formData)
      .then((response) => {
        const filePaths = response.data.output;
        finalUploadObject.extra_file = filePaths?.map((path, i) => (
          {file: path?.location, split: extraFiles[i]?.split}
        ));
        handleCreateTest(finalUploadObject);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setShowLoader(false);
        toast.warn('Error Uploading Files, Please try again later.', defaultToastProps);
      });
  };

  const handleCreateTest = (uploadObject) => {
    let testBody = { ...testData };
    testBody.name = testName;
    testBody.project_id = project?.value;
    testBody.jm_version = jmeterVersion;
    testBody.description = optionalDescription;
    testBody.user_id = userDetails?.id;
    testBody.organization=userDetails?.organization
    testBody.plan=plan[userDetails?.plan]

    const properties = {};
    keyValuePairs.forEach((obj) => {
      properties[obj.key] = obj.value;
    });
    testBody.properties = properties;
    testBody = { ...testBody, ...uploadObject };
    // if (JSON.stringify(loadAgentsArray?.[0]) !== JSON.stringify({ ...defaultRowValue })) {
    const loadAgents = loadAgentsArray?.map((agent) => ({
      location: agent.location,
      NumServers: agent.numberOfServers,
      size: agent.serverSize,
      disk_size: agent.diskSize,
      subnet_id: agent.subnetId,
      sec_group: agent.securityGroupId,
    }));
    testBody.load_agent = loadAgents;
    // }
    if (selectedPlugins?.length > 0) {
      testBody.plugins = selectedPlugins;
    }
    // ...
    delete testBody.email;
    axios
      .post(`${baseUrl}/test/add-test`, testBody)
      .then((response) => {
        navigate('/tests', { replace: true });
        toast.success('Test created successfully', defaultToastProps);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setShowLoader(false);
        console.log("---------",error.response?.data?.data?.message)
        if(error.response?.data?.data?.message)
        toast.warn(`Error Creating Test, ${error.response?.data?.data?.message}.`, defaultToastProps);
        else
        toast.warn('Error Creating Test, Please try again later.', defaultToastProps);
      });
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);

    // Filter the plugins based on the search input
    const filtered = plugins.filter((plugin) => plugin.name.toLowerCase().includes(inputValue.toLowerCase()));
    setFilteredPlugins(filtered);
  };

  const fetchAwsDetails = (region, index) => {
    if (region.includes(' - ')) region = region.substring(0, region.lastIndexOf(' - '));

    axios
      .get(`${baseUrl}/aws/my-aws-details/${region}`)
      .then((response) => {
        const sizeData = response.data.data.sizes; // Extract 'sizes' from the API response
        setSize((size) => {
          const sizeArray = [...size];
          sizeArray[index] = sizeData;
          return sizeArray;
        });
        const subnetIdData = response.data.data.subnets; // Extract 'subnets' from the API response
        setSubnetId((subnetIds) => {
          const subNetIdsArray = [...subnetIds];
          subNetIdsArray[index] = subnetIdData;
          return subNetIdsArray;
        });
        // const securityGroupIdData = response.data.data.securityGroups; // Extract 'securityGroups' from the API response
        // setSecurityGroupId((securityGroupIds) => {
        //   const securityGroupIdArray = [...securityGroupIds];
        //   securityGroupIdArray[index] = securityGroupIdData;
        //   return securityGroupIdArray;
        // });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  const fetchAwsSG = (subnetId, region, index) => {
    if (region.includes(' - ')) region = region.substring(0, region.lastIndexOf(' - '));
    // if (subnetId.includes(' - ')) subnet = subnetId.substring(0, subnetId.indexOf(' - '));

    axios
      .get(`${baseUrl}/aws/my-aws-sg/${region}/${subnetId}`)
      .then((response) => {
        const securityGroupIdData = response.data.data; // Extract 'securityGroups' from the API response
        setSecurityGroupId((securityGroupIds) => {
          const securityGroupIdArray = [...securityGroupIds];
          securityGroupIdArray[index] = securityGroupIdData;
          return securityGroupIdArray;
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    const userDetails = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));
    console.log(new Date(userDetails.valid_till), new Date());

    
    fetchProjects();
    const fetchPlugins = () => {
      axios
        .get(`${baseUrl}/test/plugins`)
        .then((response) => {
          setPlugins(response.data);
          // Initialize filtered plugins with all plugins
          setFilteredPlugins(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };

    const fetchRegions = () => {
      axios
        .get(`${baseUrl}/aws/my-aws-region`)
        .then((response) => {
          const locationData = response.data.data; // Extract 'regions' from the API response
          setLocation(locationData);
          setIsAwsSet(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };
    fetchRegions();
    fetchPlugins();
  }, []);

  useEffect(() => {
    if (testData && projects?.length > 0) {
      setTestName(testData.name);
      const selectedProject = projects?.find((project) => project.id === Number(testData.project_id));
      if (selectedProject) setProject({ value: selectedProject?.id, label: selectedProject?.name });
      setJmxFile(testData?.jmx);
      setExtraFiles(testData?.extra_file);
      setJmeterVersion(testData?.jm_version);
      setOptionalDescription(testData?.description);
      const fetchedLoadAgents = testData.load_agent?.map((loadAgent) => ({
        ...defaultRowValue,
        location: loadAgent.location,
        numberOfServers: loadAgent.NumServers,
        serverSize: loadAgent.size,
        diskSize: loadAgent.disk_size,
        securityGroupId: loadAgent.sec_group,
        subnetId: loadAgent.subnet_id,
      }));
      setLoadAgentsArray(fetchedLoadAgents);
      setSelectedPlugins(testData?.plugins);
    }
  }, [testData, projects]);

  return (
    <>
      {showLoader ? (
        <LoadIndicator />
      ) : (
        <>
          <Helmet>
            <title>Test-Form</title>
          </Helmet>
          <Header onOpenNav={() => setOpen(true)} />

          <Nav openNav={open} onCloseNav={() => setOpen(false)} />

          <Container style={{}}>
            <Typography variant="h4" sx={{ mb: 2 }} style={{ color: "black" }}>
              Create New Test
            </Typography>
            {/* {!isSubscribe && <p style={{ color: 'red' }}>Please Subscribe to Run the Test.</p>} */}
            <Stack spacing={3}>
              <Grid container xs={12} spacing={2}>
                <Grid xs={12} md={6} marginBottom={2}>
                  <CustomTextField
                    type="text"
                    label="Test Name" // Label for the input
                    size="small"
                    InputLabelProps={{ style: { color: "black" } }}
                    onChange={(e) => setTestName(e.target.value)}
                    value={testName}
                    style={{ width: mdDown ? "100%" : "90%" }}
                  />
                </Grid>
                <Grid xs={12} md={6} marginBottom={2}>
                  <CustomAutoComplete
                    disablePortal
                    id="project-combo-box"
                    size="small"
                    style={{ width: mdDown ? "100%" : "93%" }}
                    options={projects?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))}
                    value={project}
                    onChange={(e, newValue) => setProject(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ style: { color: "black" } }}
                        label="Choose Project"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="flex-start" xs={12}>
                {/* <Typography variant="body1" sx={{ fontSize: '1.25rem', marginLeft: '-15px' }}>
                JMX File
              </Typography> */}
                <Grid xs={12} md={4} marginBottom={2}>
                  <label htmlFor="upload-jmx" style={{ width: "100%" }}>
                    <input
                      style={{ display: "none" }}
                      id="upload-jmx"
                      name="upload-jmx"
                      type="file"
                      accept=".jmx"
                      onChange={handleJmxFileChange}
                      disabled={testData && testData.jmx}
                    />

                    <Button
                      variant="outlined"
                      component="span"
                      style={{
                        width: mdDown ? "100%" : "90%",
                        height: 40,
                        borderColor: "#d4d9dd",
                        color: "black",
                      }}
                      disabled={testData && testData.jmx}
                    >
                      Upload JMX File
                    </Button>

                    <Typography
                      variant="body2"
                      fontSize={10}
                      color={"GrayText"}
                    >
                      {testData ? jmxFile : jmxFile?.name}
                    </Typography>
                  </label>
                </Grid>
                <Grid xs={12} md={4} marginBottom={2}>
                  <CustomAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    style={{ width: mdDown ? "100%" : "90%" }}
                    options={[
                      "3.3",
                      "4.0",
                      "5.0",
                      "5.2.1",
                      "5.3",
                      "5.4.1",
                      "5.5",
                      "5.6.2",
                    ]}
                    value={jmeterVersion}
                    onChange={(e, newValue) => setJmeterVersion(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ style: { color: "black" } }}
                        label="JMX Version"
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={4} marginBottom={2}>
                  <Button
                    // color="secondary"
                    variant="outlined"
                    component="span"
                    style={{
                      width: mdDown ? "100%" : "90%",
                      height: 40,
                      color: "black",
                      borderColor: "#d4d9dd",
                    }}
                    onClick={handleAddExtraFile}
                    disabled={testData && testData.jmx}
                  >
                    Add Extra Files
                    <Tooltip
                      title="Data files, CSV, Plugins, Libraries. Max File Size: 150MB. (Max No. Files: 25)"
                      style={{ marginLeft: 10 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="16" x2="12" y2="12" />
                        <line x1="12" y1="8" x2="12" y2="8" />
                      </svg>
                    </Tooltip>
                  </Button>
                </Grid>
              </Grid>

              <Grid container alignItems="flex-start" xs={12}>
                {extraFiles?.map(({ file, split }, index) => (
                  <Grid item xs={12} marginBottom={2}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <label htmlFor={`upload-extra-${index}`}>
                        <input
                          style={{ display: "none" }}
                          name={`upload-extra-${index}`}
                          id={`upload-extra-${index}`}
                          type="file"
                          accept=".csv, .txt, .xls, .xlsx, .jpg, .jpeg, .png, .gif"
                          onChange={(event) =>
                            handleExtraFileUpload(event, index)
                          }
                          disabled={testData && testData.jmx}
                        />
                        <Button
                          variant="outlined"
                          component="span"
                          style={{
                            color: "black",
                            borderColor: "#d4d9dd",
                            height: 40,
                          }}
                        >
                          Upload
                          <Tooltip
                            title="Data files, CSV, Plugins, Libraries. Max File Size: 150MB. (Max No. Files: 25)"
                            style={{ marginLeft: 10 }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10" />
                              <line x1="12" y1="16" x2="12" y2="12" />
                              <line x1="12" y1="8" x2="12" y2="8" />
                            </svg>
                          </Tooltip>
                        </Button>
                      </label>
                      <Box
                        sx={{
                          width: "70%",
                          height: 40,
                          border: "1px solid grey",
                          borderRadius: 1,
                        }}
                        padding={1}
                      >
                        <Typography style={{ width: mdDown ? "100%" : "90%" }}>
                          {testData ? file: file?.name}
                        </Typography>
                      </Box>
                      <FormControlLabel
                        control={<Checkbox checked={split} />}
                        label="Split"
                        style={{ color: "black" }}
                        onChange={() => handleToggleSplit(index)}
                        labelPlacement="start"
                      />
                      <IconButton>
                        <CloseIcon
                          style={{ color: "red" }}
                          onClick={() => handleRemoveExtraFile(index)}
                        />
                      </IconButton>
                    </Stack>
                  </Grid>
                ))}
              </Grid>

              <Typography
                variant="body2"
                sx={{ fontSize: "1.15rem" }}
                color={"black"}
              >
                Optional Description
              </Typography>
              <StyledTextarea
                aria-label="minimum height"
                minRows={2}
                style={{
                  maxWidth: "97%",
                  minWidth: mdDown ? "100%" : "97%",
                  marginTop: "0px",
                }}
                name="description"
                value={optionalDescription}
                onChange={(e) => setOptionalDescription(e.target.value)}
              />
              <div>
                <Accordion style={{ width: mdDown ? "100%" : "97%" }}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: "#2065d126",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography color={"black"}>
                      Advanced Cloud Option - Load Agents{" "}
                      {isAwsSet && (
                        <p style={{ color: "red" }}>
                          {" "}
                          Please setup Your AWS account properly
                        </p>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {loadAgentsArray?.map((row, index) => (
                      <Paper
                        key={index}
                        elevation={3}
                        style={{
                          padding: "20px",
                          marginTop: "20px",
                          borderRadius: "8px",
                          backgroundColor: "#2065d10f",
                          display: "flex",
                          position: "relative",
                          flexDirection: "column",
                        }}
                      >
                        <IconButton
                          aria-label="Delete Form"
                          onClick={() => handleDeleteForm(index)}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#2065d1",
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            paddingTop: 20,
                          }}
                        >
                          <Grid container alignItems="center" xs={12}>
                            <Grid xs={12} md={4} marginBottom={2}>
                              <CustomAutoComplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                style={{ width: mdDown ? "100%" : "90%" }}
                                options={location}
                                value={row.location}
                                onChange={(_, newValue) => {
                                  // newValue = newValue.substring(0, newValue.lastIndexOf(' - '));
                                  if (newValue) {
                                    fetchAwsDetails(
                                      newValue?.substring(
                                        0,
                                        newValue?.lastIndexOf(" - ")
                                      ),
                                      index
                                    );
                                  }
                                  setLoadAgentsArray((loadAgent) => {
                                    const newRow = [...loadAgent];
                                    newRow[index].location = newValue;
                                    return newRow;
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    label="Choose Location"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid xs={12} md={4} marginBottom={2}>
                              <CustomTextField
                                type="number"
                                label="Number Of Servers"
                                size="small"
                                InputLabelProps={{ style: { color: "black" } }}
                                onChange={(e) => {
                                  setLoadAgentsArray((loadAgent) => {
                                    const newRow = [...loadAgent];
                                    newRow[index].numberOfServers =
                                      e.target.value;
                                    return newRow;
                                  });
                                }}
                                defaultValue={1}
                                style={{ width: mdDown ? "100%" : "90%" }}
                              />
                            </Grid>
                            <Grid xs={12} md={4} marginBottom={2}>
                              <CustomAutoComplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                style={{ width: mdDown ? "100%" : "90%" }}
                                options={size[index] || []}
                                value={row?.serverSize}
                                disabled={row.location === ""}
                                onChange={(_, newValue) => {
                                  setLoadAgentsArray((loadAgent) => {
                                    const newRow = [...loadAgent];
                                    const newArr = newValue?.split(" - ") || "";
                                    newRow[index].serverSize = newArr[0];
                                    newRow[index].cpuSize =
                                      newArr[1]?.substring(
                                        1,
                                        newArr[1]?.indexOf(" ")
                                      );
                                    return newRow;
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    label="Choose Server Size"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid xs={12} container paddingTop={0}>
                              <Grid xs={12} md={6}>
                                <CustomTextField
                                  type="number"
                                  label="Disk Size"
                                  size="small"
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  onChange={(e) => {
                                    setLoadAgentsArray((loadAgent) => {
                                      const newRow = [...loadAgent];
                                      newRow[index].diskSize = e.target.value;
                                      return newRow;
                                    });
                                  }}
                                  defaultValue={16} // Set the default value to 16
                                  style={{ width: mdDown ? "100%" : "60%" }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid container xs={12} marginTop={2}>
                          <Grid xs={12} md={4} marginBottom={2}>
                            <CustomAutoComplete
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              style={{ width: mdDown ? "100%" : "90%" }}
                              options={subnetId[index] || []}
                              value={row?.subnetId}
                              disabled={row.location === ""}
                              onChange={(_, newValue) => {
                                if (newValue) {
                                  fetchAwsSG(
                                    newValue?.substring(
                                      0,
                                      newValue?.indexOf(" - ")
                                    ),
                                    loadAgentsArray[index].location,
                                    index
                                  );
                                }
                                setLoadAgentsArray((loadAgent) => {
                                  const newRow = [...loadAgent];
                                  newRow[index].subnetId = newValue?.substring(
                                    0,
                                    newValue?.indexOf(" - ")
                                  );
                                  return newRow;
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  label="Subnet Id"
                                />
                              )}
                            />
                          </Grid>
                          <Grid xs={12} md={4} marginBottom={2}>
                            <CustomAutoComplete
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              style={{ width: mdDown ? "100%" : "90%" }}
                              options={securityGroupId[index] || []}
                              value={row.securityGroupId}
                              disabled={row.location === ""}
                              onChange={(_, newValue) => {
                                setLoadAgentsArray((loadAgent) => {
                                  const newRow = [...loadAgent];
                                  newRow[index].securityGroupId = newValue;
                                  return newRow;
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  label="Security Group Id"
                                />
                              )}
                            />
                          </Grid>
                          <Grid xs={12} md={4} marginBottom={2}>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="If running in a VPC subnet, associate a public IP address with this instance."
                              style={{ color: "black" }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                    <div style={{ marginTop: "25px" }}>
                      <Button
                        variant="outlined"
                        style={{
                          width: "200px",
                          marginLeft: "36%",
                          borderColor: "#d4d9dd",
                          color: "black",
                        }}
                        onClick={handleLoadAgentsClick}
                      >
                        Add Load Agents
                      </Button>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div>
                <Accordion style={{ width: mdDown ? "100%" : "97%" }}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: "#2065d126",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography color={"black"}>
                      Advanced Cloud Option
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {keyValuePairs?.length > 0 && (
                      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "1.15rem" }}
                          color={"black"}
                        >
                          Extracted Key-Value Pairs from JMX File:
                        </Typography>
                        <div style={{ marginTop: 10 }}>
                          {keyValuePairs?.map((pair, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                              }}
                            >
                              <TextField
                                disabled
                                label="Key"
                                value={pair.key}
                                size="small"
                                style={{ width: "20%", marginRight: "10px" }}
                              />
                              <TextField
                                label="Value"
                                value={pair.value}
                                size="small"
                                style={{ width: "7%" }}
                                onChange={(e) => {
                                  const newPairs = [...keyValuePairs];
                                  newPairs[index].value = e.target.value;
                                  setKeyValuePairs(newPairs);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* <Typography color={'black'}>Specify JMeter Options as -Jkey=value -Jkey=value</Typography>
                <StyledTextarea
                  aria-label="minimum height"
                  minRows={2}
                  style={{ minWidth: '100%', maxWidth: '100%', marginTop: '5px' }}
                  // placeholder="Option String"
                /> */}
                  </AccordionDetails>

                  <AccordionDetails>
                    <Typography color={"black"}>
                      Load from user.properties
                    </Typography>
                    <div style={{ marginTop: "0px" }}>
                      <input
                        type="file"
                        accept=".jmx"
                        onChange={(e) => setUserPropFiles(e.target.files[0])}
                        disabled={testData && testData.jmx}
                        style={{
                          border: "1px solid #D3D3D3",
                          borderRadius: "6px",
                          padding: "16.5px",
                          width: "100%",
                          color: "black",
                        }}
                      />
                    </div>
                  </AccordionDetails>

                  <AccordionDetails>
                    <Typography color={"black"}>
                      Plugins for JMeter Tests:-
                    </Typography>
                  </AccordionDetails>
                  <AccordionDetails>
                    <Paper
                      elevation={3}
                      style={{
                        padding: "20px",
                        marginTop: "20px",
                        borderRadius: "8px",
                        backgroundColor: "#2065d10f",
                        display: "flex",
                        position: "relative",
                        flexDirection: "column",
                      }}
                    >
                      {selectedPlugins?.map((data, index) => (
                        <div
                          style={{
                            width: "100%",
                            margin: 10,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            border: "1px solid #fff",
                            backgroundColor: "white",
                            padding: 12,
                            borderRadius: 6,
                          }}
                        >
                          <IconButton
                            color="secondary"
                            aria-label="Delete Form"
                            onClick={() => handleDeletePlugin(index)}
                            style={{ position: "absolute", top: 0, right: 0 }}
                          >
                            <CancelIcon />
                          </IconButton>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                            }}
                          >
                            <Typography variant="h6">{data?.name}</Typography>
                            <Typography variant="body2">
                              {data?.description}
                            </Typography>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              <Typography variant="caption">
                                {data?.version}
                              </Typography>
                            </div>
                          </div>
                          <img
                            alt=""
                            src={data?.screenshotUrl}
                            style={{ width: "30%" }}
                          />
                        </div>
                      ))}
                      <TextField
                        label="Search Plugins"
                        variant="outlined"
                        size="small"
                        onChange={handleSearchInputChange}
                        value={searchInput}
                        style={{
                          marginBottom: "10px",
                          width: "50%",
                          marginTop: 20,
                        }}
                        InputLabelProps={{
                          style: { color: "black" }, // Set the label color
                        }}
                        inputProps={{
                          style: { borderColor: "black" }, // Set the border color
                        }}
                      />

                      <Typography style={{ fontSize: "1rem" }} color={"black"}>
                        Selected Plugins:{" "}
                        <span style={{ color: "#2065d1" }}>
                          {selectedPlugins?.length}
                        </span>
                      </Typography>
                      {searchInput ? (
                        <>
                          {filteredPlugins.length > 0 ? (
                            <>
                              {filteredPlugins?.map((data, index) => (
                                <div
                                  style={{
                                    width: "100%",
                                    margin: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    border: "1px solid #fff",
                                    backgroundColor: "white",
                                    padding: 12,
                                    borderRadius: 6,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "60%",
                                    }}
                                  >
                                    <Typography variant="h6">
                                      {" "}
                                      {data?.name}
                                    </Typography>
                                    <Typography variant="body2">
                                      {data?.description}
                                    </Typography>
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {Object.keys(data?.versions)?.map(
                                        (key) => (
                                          <Button
                                            style={{ color: "#2065d1" }}
                                            size="small"
                                            onClick={() => {
                                              const selectedData = {
                                                ...data,
                                                version: key,
                                                ...data?.versions?.[key],
                                              };
                                              delete selectedData.versions;
                                              setSelectedPlugins((plugins) => [
                                                ...plugins,
                                                selectedData,
                                              ]);
                                              setPlugins((plugins) => {
                                                const newArray = [...plugins];
                                                newArray.splice(index, 1);
                                                return newArray;
                                              });
                                            }}
                                          >
                                            {key}
                                          </Button>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <img
                                    alt=""
                                    src={data?.screenshotUrl}
                                    style={{ width: "30%" }}
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <div>
                              <Typography
                                style={{ fontSize: 20 }}
                                color={"black"}
                              >
                                Plugin not found
                              </Typography>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {plugins.slice(0, 2)?.map((data, index) => (
                            <div
                              style={{
                                width: "100%",
                                margin: 10,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                border: "1px solid #fff",
                                backgroundColor: "white",
                                padding: 12,
                                borderRadius: 6,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "60%",
                                }}
                              >
                                <Typography variant="h6">
                                  {" "}
                                  {data?.name}
                                </Typography>
                                <Typography variant="body2">
                                  {data?.description}
                                </Typography>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {Object.keys(data?.versions)?.map((key) => (
                                    <Button
                                      style={{ color: "#2065d1" }}
                                      size="small"
                                      onClick={() => {
                                        const selectedData = {
                                          ...data,
                                          version: key,
                                          ...data?.versions?.[key],
                                        };
                                        delete selectedData.versions;
                                        setSelectedPlugins((plugins) => [
                                          ...plugins,
                                          selectedData,
                                        ]);
                                        setPlugins((plugins) => {
                                          const newArray = [...plugins];
                                          newArray.splice(index, 1);
                                          return newArray;
                                        });
                                      }}
                                    >
                                      {key}
                                    </Button>
                                  ))}
                                </div>
                              </div>
                              <img
                                alt=""
                                src={data?.screenshotUrl}
                                style={{ width: "30%" }}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  style={{ maxWidth: 200 }}
                  onClick={handleValidation}
                >
                  {testData ? "Re-run Test " : "Start Test"}
                </Button>
                <Button
                  variant="contained"
                  style={{ maxWidth: 200, marginLeft: "20px" }}
                  onClick={handleBackButtonClick}
                >
                  {" "}
                  {"Cancel"}{" "}
                </Button>
              </div>
            </Stack>
          </Container>
        </>
      )}
      <Snackbar
        open={isFileTypeAlertOpen}
        autoHideDuration={3000}
        onClose={closeFileTypeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error" onClose={closeFileTypeAlert}>
          {fileTypeAlertMessage}
        </Alert>
      </Snackbar>
      <Popover
        open={isPopoverOpen}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center", // Center vertically
          horizontal: "center", // Center horizontally
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        classes={{ paper: "centered-popover" }} // Add a custom CSS class
      >
        <Paper sx={{ padding: 4, width: 400 }}>
          <div className="popover-header">
            <IconButton
              size="small"
              onClick={handleClosePopover}
              style={{ position: "absolute", top: 1, right: 1 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ marginTop: 50 }}>
            {`Your subscription of ${userDetails.plan} Plan has expired.\n 
             `}
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={() => navigate("/Subscriptions")}
          >
            Click here to subscribe
          </Button>
        </Paper>
      </Popover>
    </>
  );
}
