import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { baseUrl, userSessionData } from '../../utils/constants';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [dashboard, setDashboard] = useState([]);
  const user = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));

  userSessionData();

  useEffect(() => {
    const fetchProjects = async () => {
      const endpoints = user?.role === 'admin' ? 'admin-dashboard' : 'dashboard';
      axios
        .get(`${baseUrl}/users/${endpoints}`)
        .then((res) => {
          if (res && res.data.status === 200) {
            setDashboard(res.data.data);
          }
        })
        .catch((err) => {
          console.log('error');
        });
    };
    fetchProjects();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total Projects" total={dashboard.project} icon={'ant-design:project-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Totl Tests"
              total={dashboard.test}
              color="warning"
              icon={'ant-design:build-filled'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Users"
              total={dashboard.user}
              color="info"
              icon={'ant-design:usergroup-add-outlined'}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={12}>
            <AppCurrentVisits
              title="Test Status"
              chartData={[
                { label: 'Completed', value: (dashboard?.test_status?.completed_tests / dashboard?.test_total) * 100 },
                { label: 'Cancelled', value: (dashboard?.test_status?.cancelled_tests / dashboard?.test_total) * 100 },
                {
                  label: 'In-progress',
                  value: (dashboard?.test_status?.inprogress_tests / dashboard?.test_total) * 100,
                },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
