import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
// components
import { toast } from 'react-toastify';

import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/User';
import '../../theme/popup.css';
import { applySortFilter, getComparator } from '../../utils/Utilities';
import { baseUrl, defaultToastProps, userSessionData } from '../../utils/constants';
import DeleteDailog from './deleteDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  // { id: 'users', label: 'Users', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

export default function ProjectPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isBlurActive, setIsBlurActive] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const user = sessionStorage.getItem('authUser') && JSON.parse(sessionStorage.getItem('authUser'));
  const { userId } = useParams();
  const handleOpenPopover = (rowData) => {
    if (rowData) {
      setName(rowData.name);
      setDescription(rowData.description);
    }
    setIsPopoverOpen(true);
    setIsBlurActive(true); // Activate blur when the popover opens
  };

  const handleProjectSubmit = () => {
    if (!name || !name.trim()) {
      // Show a warning toast if the "Name" field is empty or contains only whitespace
      toast.warn('Please enter a valid name', defaultToastProps);
      return; // Do not submit if the name is empty or contains only whitespace
    }

    // Check if the name already exists in the list of rows
    const nameExists = rows.some((row) => row.name === name);

    if (nameExists && (!selectedRow || (selectedRow && selectedRow.name !== name))) {
      // Show a warning toast if the name already exists
      toast.warn('Name already exists, please choose a different name', defaultToastProps);
      return; // Do not submit if the name already exists
    }

    if (selectedRow) {
      axios
        .put(`${baseUrl}/projects/edit/${selectedRow?.id}`, {
          name,
          description,
        })
        .then((response) => {
          handleClosePopover();
          toast.success('Project updated successfully', defaultToastProps);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000); // Delay the reload by 1 second (you can adjust the delay as needed)
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`${baseUrl}/projects/add-project`, {
          name,
          description,
        })
        .then((response) => {
          handleClosePopover();
          toast.success('Project created successfully', defaultToastProps);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setIsBlurActive(false); // Deactivate blur when the popover closes
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName, 'name');

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleDeleteApiCall = async (id) => {
    setOpenDeleteModal(false);
    setSelectedRow(null);
    axios
      .delete(`${baseUrl}/projects/remove/${id}`)
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        toast.warn('Test exists for this Project');
        console.log('error');
      });
  };

  const fetchData = async (offset, limit) => {
    const endpoints = user?.role === 'admin' ? 'all' : 'my-project';
    let url=`${baseUrl}/projects/${endpoints}?limt=${limit}&offset=${offset}`
    if(userId)
    url=`${baseUrl}/projects/user-project/${userId}?limt=${limit}&offset=${offset}`
    axios
      .get(url)
      .then((res) => {
        if (res && res.data.status === 200) {
          setRows(res.data.data.list);
          setTotalCount(Number(res.data.data?.list?.length));
        }
      })
      .catch((err) => {
        console.log('error');
      });
  };



  useEffect(() => {
    fetchData(offset, limit);
  }, [limit, offset]);

  return (
    <>
      <div className={`container ${isBlurActive ? 'blur' : ''}`}>
        <div className={`background-blur ${isBlurActive ? 'active' : ''}`} />
        <Helmet>
          <title> Projects</title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Projects
            </Typography>
            {userSessionData()?.role !== 'admin' && (
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenPopover}>
                New Project
              </Button>
            )}
          </Stack>

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={rows.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    hideCheckBox
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, role, description, avatarUrl } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{description}</TableCell>

                          <TableCell align="right">
                      {user?.role !== 'admin' && (
                        <>
                          <IconButton
                            size="large"
                            onClick={() => {
                              setSelectedRow(row);
                              handleOpenPopover(row);
                            }}
                          >
                            <Iconify icon={'eva:edit-fill'} />
                          </IconButton>

                          <IconButton
                            size="large"
                            sx={{ color: 'error.main' }}
                            onClick={() => {
                              setSelectedRow(row);
                              setOpenDeleteModal(true);
                            }}
                          >
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        {openDeleteModal && (
          <DeleteDailog
            data={selectedRow}
            handleClose={() => {
              setOpenDeleteModal(false);
              setSelectedRow(null);
            }}
            handleDeleteClicked={(data) => handleDeleteApiCall(data?.id)}
            visible={openDeleteModal}
          />
        )}

        <Popover
          open={isPopoverOpen}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'center', // Center vertically
            horizontal: 'center', // Center horizontally
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          classes={{ paper: 'centered-popover' }} // Add a custom CSS class
        >
          <Paper sx={{ padding: 4, width: 400 }}>
            <div className="popover-header">
              <IconButton size="small" onClick={handleClosePopover} style={{ position: 'absolute', top: 1, right: 1 }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: 50 }}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Name"
                margin="normal"
                fullWidth
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: 0 }}>
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <Button variant="contained" color="primary" onClick={handleProjectSubmit} style={{ marginTop: 20 }}>
              Submit
            </Button>
          </Paper>
        </Popover>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem>
            <IconButton>
              <Iconify icon={'eva:edit-fill'} />
            </IconButton>
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}>
            <IconButton>
              <Iconify icon={'eva:trash-2-outline'} />
            </IconButton>
          </MenuItem>
        </Popover>
      </div>
    </>
  );
}
