import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { baseUrl, defaultToastProps } from '../../utils/constants';
import Iconify from '../../components/iconify';

export default function SignupForm() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleFormValidation = () => {
    let errorMessage;
    if (!firstName) {
      errorMessage = 'Please enter First Name';
    } else if (!lastName) {
      errorMessage = 'Please enter Last Name';
    } else if (!email) {
      errorMessage = 'Please enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errorMessage = 'Please enter a valid email';
    } else if (!organization) {
      errorMessage = 'Please enter a valid organization';
    } else if (!password) {
      errorMessage = 'Please enter password';
    } else if (!confirmPassword) {
      errorMessage = 'Please confirm entered password';
    } else if (password !== confirmPassword) {
      errorMessage = 'Please enter correct password';
    }
    if (errorMessage) {
      toast.warn(errorMessage, defaultToastProps);
    } else {
      handleSignUpClick();
    }
  };

  function handleSignUpClick() {
    axios
      .post(`${baseUrl}/users/create`, {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        organization,
        role:'orgAdmin'
      })
      .then((res) => {
        axios.defaults.headers.common.token = res.data?.data?.token;
        sessionStorage.setItem('authUser', JSON.stringify(res.data.data));
        navigate('/dashboard', { replace: true });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <TextField
            name="firstName"
            type="text"
            label="First Name"
            fullWidth
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            name="lastName"
            type="text"
            label="Last Name"
            fullWidth
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Stack>
        <TextField
          name="email"
          type="email"
          label="Email address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          name="organization"
          type="text"
          label="Organization"
          required
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          {/* Forgot password? */}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleFormValidation}>
        Signup
      </LoadingButton>
    </>
  );
}
